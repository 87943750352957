<template>
  <div class="main_content">
      <div class="tips_box">欢迎使用！<span>卫健公社健康管理系统</span></div>
  </div>
</template>

<script>

export default {
  name: 'home',
  data () {
    return {

    }
  },
  created () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.main_content{
  background-image: url("../../assets/images/banner.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .tips_box{
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 3.75rem;
    color: #00b8b8;
    justify-content: center;
    padding-left:15.625rem;
    background-image: -webkit-linear-gradient(bottom, #3be7de, #00b8b8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: YouSheBiaoTiHei;
  }
}
</style>
